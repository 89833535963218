@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css?family=Vollkorn&display=swap");
@import url("https://fonts.googleapis.com/css?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css?family=Vollkorn&display=swap");
.standard__projects {
  padding: 20px; }

.standard__project-image {
  height: 240px;
  width: 100%;
  object-fit: cover; }

.standard__project-image-info {
  position: relative;
  width: 100%; }

.standard__project-info {
  position: absolute;
  bottom: 4px;
  display: flex;
  flex-direction: row-reverse;
  width: 100%; }

.standard__project-value-box {
  background-color: #ffff;
  width: 55%;
  padding: 0 10px; }

.standard__project-value {
  font-size: 14px;
  font-weight: bolder;
  line-height: 1.85;
  letter-spacing: 1px;
  color: #0d0820;
  text-align: left;
  padding-left: 10px; }

.standard__project-date-box {
  background-color: #0d0820;
  width: 60%; }

.standard__project-date {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.85;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 5px;
  text-align: center;
  font-weight: bolder;
  min-width: 150px; }

.standard__project-title {
  font-size: 20px;
  letter-spacing: -0.25px;
  color: #0d0820;
  margin-bottom: 3px; }

.standard__project-main-description {
  font-size: 16px;
  line-height: 1.5;
  color: #3d394d; }

.standard__see-project {
  font-size: 14px;
  line-height: 1.13;
  color: #2386b2;
  margin: 5px 0;
  text-align: left;
  cursor: pointer;
  width: 90%;
  text-decoration: none; }

.standard__project-button {
  width: 50%;
  height: 30px;
  margin: 10px 0 5px;
  display: flex; }

.standard__individual-project {
  margin-bottom: 20px; }

.standard__no-projects-box {
  border: 1px solid #bcc3c6;
  width: 70%;
  display: flex;
  margin: 20px auto;
  padding: 10px 5px; }

.standard__no-projects {
  text-align: center;
  color: #0d0820;
  font-weight: bolder;
  margin: 0 auto; }

.standard__submitted-title {
  margin: 10px 0 15px 15px; }

@media (min-width: 768px) and (max-width: 1023px) {
  .standard__projects__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
  .standard__individual-project {
    width: 45%;
    margin: 10px 10px 30px; }
  .standard__submitted-title {
    margin-left: 13%; }
  .standard__projects-wrapper-few {
    justify-content: left;
    margin-left: 10%; } }

@media (min-width: 1024px) {
  .standard__projects__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 5%; }
  .standard__individual-project {
    width: 30%;
    margin: 10px 10px 30px; }
  .standard__project-image-info {
    border: 1px solid #bcc3c6; }
  .standard__project-info {
    bottom: 0; }
  .standard__project-date-box {
    width: 60%; }
  .standard__project-value-box {
    width: 40%; }
  .standard__project-value {
    text-align: center;
    margin: 5px auto 0; }
  .standard__project-image {
    height: 300px;
    width: 100%;
    margin-bottom: 0; }
  .standard__project-button {
    width: 35%; }
  .standard__no-projects-box {
    border: 1px solid #bcc3c6;
    width: 40%;
    display: flex;
    margin: 50px 10%;
    padding: 10px 5px; }
  .standard__submitted-title {
    margin: 20px 0 20px 12%;
    font-size: 22px; }
  .standard__projects-wrapper-few {
    justify-content: left; } }

h1, h2, h3, li {
  font-family: 'Vollkorn', serif;
  margin: 0; }

h4, h5, p {
  font-family: 'Ubuntu', sans-serif;
  margin: 0; }

p {
  font-size: 16px; }

#programs-cards {
  margin-top: 0; }

.standard {
  scroll-behavior: smooth;
  box-sizing: border-box;
  position: relative;
  overflow: hidden; }
  .standard__title-box {
    display: flex;
    flex-direction: row; }
  .standard__first-section {
    background-image: url("../assets/images/generalPresentation/pattern.png");
    padding: 20px 20px 30px; }
  .standard__generic {
    font-size: 14px;
    line-height: 1.71;
    color: #605b71; }
  .standard__title-page {
    color: #0d0820; }
  .standard__main-title {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.5px;
    color: #0d0820;
    margin-top: 8px; }
  .standard__card {
    background-color: #fff;
    font-size: 20px;
    color: #0d0820;
    padding: 25px 20px 10px 20px;
    margin-top: 20px;
    height: auto; }
  .standard__card-title {
    font-size: 18px;
    margin-bottom: 5px; }
  .standard__ordinary-title {
    font-size: 16px; }
  .standard__card-description {
    font-size: 14px;
    line-height: 1.5;
    color: #3d394d;
    text-align: justify;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; }
  .standard__card-description-empty {
    display: none; }
  .standard__card-description-details {
    font-size: 14px;
    line-height: 1.5;
    color: #3d394d;
    text-align: justify;
    white-space: wrap;
    overflow: auto; }
  .standard__general-description {
    white-space: normal;
    overflow: initial;
    text-overflow: initial;
    text-align: initial;
    text-indent: 40px;
    width: 90%; }
  .standard__card-responsable {
    font-size: 14px;
    font-size: 400;
    padding-bottom: 5px;
    color: #404040; }
  .standard__button {
    width: 100%;
    height: 45px;
    border: none;
    background-color: #2386b2;
    cursor: pointer; }
  .standard__button-writing {
    color: #ffff;
    margin: auto !important; }
  .standard__arrow-icon {
    width: 20px; }
  .standard__search-icon {
    width: 16px;
    cursor: pointer; }
  .standard__input-box {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .standard__input {
    border: none;
    border-bottom: 1px solid #e0eaee;
    text-indent: 3px;
    font-family: Ubuntu;
    height: 25px; }
  .standard input::placeholder {
    opacity: 0.8;
    font-family: Ubuntu;
    font-size: 14px;
    text-indent: 3px;
    color: #0d0820; }
  .standard__wrapper {
    padding: 15px;
    margin-top: 10px; }
  .standard__mp-input-box {
    margin-bottom: 20px; }
  .standard__mp-input {
    width: 85%; }
  .standard__mp-icon {
    right: 25px;
    width: 15px; }
  .standard__cards {
    margin-top: 30px;
    display: flex;
    flex-direction: column; }
  .standard__individual-card {
    border: solid 1px #c4cacd;
    padding: 10px;
    margin: 10px;
    position: relative;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; }
  .standard__card-title {
    font-size: 16px;
    letter-spacing: -0.25px;
    color: #0d0820;
    margin-bottom: 0px;
    width: 85%; }
  .standard__card-date {
    font-size: 13px;
    font-weight: bolder;
    line-height: 1.85;
    letter-spacing: 1px;
    color: #605b71;
    margin-bottom: 10px; }
  .standard__card-img {
    width: 22px;
    position: absolute;
    right: 14px;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto; }
  .standard__wrapper-no-search {
    margin-top: 0;
    padding-top: 0; }
  .standard__educational-stimulents {
    margin-top: 0; }
  .standard__list-item {
    padding: 5px 0;
    font-weight: bolder;
    color: #262626; }
  .standard__death-record-wrapper {
    padding: 10px 20px; }
  .standard__death-record-title {
    margin: 15px 0 5px; }
  .standard__description-right-side {
    color: #ffff;
    font-weight: lighter;
    font-size: 16px; }
  .standard__unordered-document-list {
    padding: 0;
    margin: 0 25px; }
    .standard__unordered-document-list li {
      color: #404040; }
  .standard__civil-status-info {
    margin: 10px 0; }
  .standard__civil-status-title {
    border-bottom: 1px solid #2386b2;
    width: 90%;
    margin-left: 10px; }
  .standard__number-box {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #000033; }
  .standard__dispozition-number {
    color: #fff;
    padding: 3px;
    font-weight: lighter; }
  .standard .MuiNativeSelect-select {
    border-bottom: 1px solid #e0eaee;
    font-family: Ubuntu;
    font-weight: lighter; }
  .standard .MuiNativeSelect-select:focus {
    background: transparent; }
  .standard__input:focus {
    outline: none; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .standard__first-section {
      padding: 30px 20px 30px 100px; }
    .standard__cards {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center; }
    .standard__individual-card {
      width: 35%;
      margin: 10px; }
    .standard__mp-input {
      width: 55%; }
    .standard__mp-input-box {
      margin: 10px 95px 20px; }
    .standard__death-record-wrapper {
      padding: 10px 50px; }
    .standard__civil-status-title {
      width: 70%; }
    .standard__search-general-area {
      margin-left: 10%; } }
  @media (min-width: 1024px) {
    .standard__taxes-link {
      text-decoration: none; }
    .standard__search-archive {
      display: flex;
      flex-direction: row; }
    .standard__taxes {
      width: 50%;
      font-size: 22px;
      padding: 10px 0;
      font-weight: light; }
    .standard__first-section {
      padding: 50px 10% 55px; }
    .standard__cards {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 80%;
      margin: 40px 0 30px 10%; }
    .standard__wrapper {
      min-height: 600px; }
    .standard__generic {
      font-size: 16px; }
    .standard__individual-card {
      width: 25%;
      margin: 10px; }
    .standard__mp-input {
      width: 45%; }
    .standard__mp-input-box {
      margin: 10px 10% 20px; }
    .standard__educational-stimulents {
      margin-top: 0; }
    .standard__wrapper-ed-stimulents {
      display: flex;
      margin-left: 5%; }
    .standard__individual-ed-stimulents {
      width: 30%; }
    .standard__document-list {
      margin-left: 10%; }
    .standard__child-allowances-title {
      margin-left: 100px !important; }
    .standard__death-record-wrapper {
      padding: 10px 200px 30px; }
    .standard__list-item {
      font-size: 18px; }
    .standard__card-description {
      font-size: 16pxș; }
    .standard__death-record-title {
      font-size: 18px; }
    .standard p {
      font-size: 18px; }
    .standard__mariage-record-left {
      margin-left: 10%; }
    .standard__civil-status-title {
      width: 35%; }
    .standard__common-title {
      margin-left: 10% !important; }
    .standard #programs-cards {
      margin-left: 40px;
      width: 95%; }
    .standard #programs-title {
      margin-left: 20%; }
    .standard #concilors-search-button {
      margin: 14px 0; }
    .standard #educational-stimulents-title {
      margin-left: 30%; }
    .standard #required-width-ed-stimulents {
      width: 100%; }
    .standard #left-main-title {
      margin-left: 20%; } }
