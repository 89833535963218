.gp-page__content {
  margin: 8% 0;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .gp-page__content {
      width: 750px; } }
  @media (min-width: 1024px) {
    .gp-page__content {
      width: 970px; } }

.gp-page__hex-grid {
  display: inline-block;
  width: 90%;
  height: auto;
  margin: 0 auto;
  font-size: 15px; }
