.aid__project-img {
  width: 100%;
  height: 350px; }

@media (min-width: 768px) and (max-width: 1023px) {
  .aid__project-img {
    margin: 20px 20px 0;
    width: 90%; }
  .aid__individual-card {
    width: 80%; } }

@media (min-width: 1024px) {
  .aid__individual-card {
    width: 80%;
    position: relative;
    bottom: 100px; }
  .aid__project-img {
    margin: 20px 20%;
    width: 50%;
    height: auto; }
  .aid__cards {
    margin-left: 19%; }
  .aid #aid-subtitle {
    position: relative;
    bottom: 100px !important;
    margin-top: 30px;
    margin-bottom: 30px; } }
