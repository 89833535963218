.admin-anouncements-page__title {
  text-align: center;
  padding-top: 50px;
  color: #1D64F0; }

.admin-anouncements-page__content {
  margin-left: 5%; }

.announcement-form {
  padding: 2% 3%; }
  .announcement-form__title {
    text-align: center; }
  .announcement-form__field {
    text-align: center; }
  .announcement-form__submit {
    margin-top: 20px;
    text-align: center; }
  .announcement-form__reset {
    margin-top: -40px;
    text-align: right; }
