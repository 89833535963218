.login-page {
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  overflow: hidden; }
  .login-page__contact {
    position: absolute;
    bottom: 30%;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center; }
  .login-page__contact-item {
    text-decoration: none;
    color: black;
    padding: 20px; }
