.footer {
  scroll-behavior: smooth;
  background-color: #0d0820;
  padding: 25px 0 35px; }
  .footer__subtitle, .footer__secondary {
    display: none; }
  .footer__list {
    list-style-type: none;
    padding-left: 0; }
  .footer__wrapper {
    margin: 0 auto; }
  .footer__title {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
    font-weight: lighter;
    margin: 8px auto;
    display: block !important; }
  .footer__item {
    display: none; }
  .footer__up {
    width: 25px;
    margin: 20px auto;
    display: flex;
    cursor: pointer; }
  .footer__copy {
    border-top: 0.5px solid #e0eaee;
    padding-top: 15px; }
  .footer__copyright {
    width: 90%;
    font-size: 14px;
    margin: 1px auto;
    color: #ffff;
    font-weight: lighter;
    text-align: center; }
  .footer__link {
    text-decoration: none;
    color: #ffff;
    cursor: pointer; }
  .footer__no-mobile {
    display: none; }
  .footer__outer-link {
    text-decoration: none; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .footer__copyright {
      width: 70%; } }
  @media (min-width: 1024px) {
    .footer__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 10px 0 0 5%; }
    .footer__subtitle, .footer__secondary {
      display: inline-block; }
    .footer__subtitle {
      padding: 3px 0; }
    .footer__column {
      display: flex;
      flex-direction: column;
      width: 22%; }
    .footer__subtitle {
      color: #ffff;
      font-weight: lighter;
      font-size: 13px; }
    .footer__title {
      text-align: initial;
      padding: 5px 0;
      font-weight: normal; }
    .footer__item {
      display: block;
      color: #ffff;
      font-size: 14px;
      margin: 5px 0; }
    .footer__copyright {
      width: 40%; }
    .footer__no-mobile {
      text-align: initial;
      padding: 5px 0;
      font-size: 14px;
      line-height: 1.5;
      color: #ffffff;
      font-weight: lighter;
      margin: 8px 0;
      display: block; } }
