@import './media-queries.scss';
@import './Pagination.scss';

.admin-standard-page {
  .default-title{
    width: 60%;
    border-bottom:6px solid #fac756;
    border-radius:3px 3px;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 15px;
    margin-bottom: 18px;
  }

  h1 {
    text-align: center;
    padding-top: 50px;
    color: #1D64F0;
  }

  &__content {
    margin-left: 5%;

  }

  &__existing-items {
    margin-top: 3%;
  }

}

.existing-items {
  padding: 3%;

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border-bottom: 1px solid #e1e5eb;
  }

  &__content-card {
   display:flex;
   flex-direction: row;
   width: 100%;
   border-bottom: 1px solid #e0e2e8;
   padding: 10px;
  }

  &__actions-card {
    width: 20%;
    display: flex;
    justify-content: space-between;
  }
}

.admin-form {
  padding: 2% 5%;

  &__title {
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: -0.4px;
    color: #16233f;
    width:100%; }

  &__field {
    display:flex;
    flex-direction: column;
  }
  &__field-box{
    margin:20px 0;
  }

  &__checkbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__reset {
    margin-top: -30px;
    text-align: right;
  }

  &__upload, &__upload-content {
    width: 672px;
  }

  &__upload-files {
    h3 {
      text-align: center;
    }
  }

  &__field-select {
    width: 30%;
  }

}

.existing-items-title{
  font-size:16px !important;
}
.major-provisions-title{
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.44px;
  color: #16233f;
}
.action-title{
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: -0.38px;
  color: #4987c2;
  cursor: pointer;
}
.delete-button{
  color:#9c1006;
  cursor: pointer;
}

.card-content{
  width: 80%;
}
.checkbox-input{
  position: relative;
  top: 15px;
  margin-left: 5px;
}
#number-field{
  width:8%;
}
.consent{
  display: flex;
  flex-direction: row;
  margin-top:20px;
  input{
    cursor: pointer;
  }
  h5{
    margin:3px 0 0 5px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: -0.25px;
    color: #16233f;
  }
}
