.pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
  padding-bottom: 10px;

  li {
    cursor: pointer;
    color: #2979c6;
    padding: 10px 0;
    margin: 0 10px;

    a {
      padding: 10px 15px;
    }

    a:focus {
      outline: none;
    }

    &:hover {
      background-color: #2979c6;
      color: white;
      border-radius: 20px;
    }
  }

  .active {
    border: 1px solid #2979c6;
    border-radius: 20px;
  }

  .previous, .next {
    border: 1px solid #2979c6;
    border-radius: 20px;
  }
}
