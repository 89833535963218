.proposal-form__content {
  padding: 20px; }

.proposal-form__box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px; }
  .proposal-form__box img {
    width: 18px;
    height: 18px;
    margin-right: 5px; }

.proposal-form__name {
  font-size: 16px; }

.proposal-form__link {
  color: #000066;
  font-weight: lighter;
  cursor: pointer; }

@media (min-width: 768px) and (max-width: 1023px) {
  .proposal-form__content {
    padding: 20px 40px; } }

@media (min-width: 1024px) {
  .proposal-form__name {
    padding-right: 10px; }
  .proposal-form__content {
    padding: 40px 10%; } }
