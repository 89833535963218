.legislation__list {
  list-style-type: none;
  margin: 20px auto;
  padding-right: 15px; }

.legislation__link {
  text-decoration: none;
  color: #0C0C0C; }

.legislation__link:hover {
  cursor: pointer;
  color: #191B53; }

.legislation__item {
  cursor: pointer;
  padding: 5px 0;
  text-indent: 5px; }

.legislation__items-container {
  display: flex;
  flex-direction: row; }

.legislation__svg {
  width: 20px;
  height: 16px;
  position: relative;
  top: 10px; }

@media (min-width: 768px) {
  .legislation__list {
    margin: 30px 7%; } }

@media (min-width: 1024px) {
  .legislation__list {
    margin: 30px 0 30px 7%;
    width: 60%; }
  .legislation__svg {
    top: 20px;
    left: 5px; }
  .legislation__item {
    padding: 10px 0;
    width: 100%; } }
