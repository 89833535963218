.allocations-document-right li {
  color: #ffff;
  font-weight: lighter; }

.allocations-title {
  border: 1px solid #000080;
  padding: 3px;
  text-align: center; }

.allocations-title-switch {
  border: none;
  font-weight: lighter; }

.allocations-wrapper {
  padding: 0;
  margin-top: 0; }

.allocations-download-title {
  color: #ffff;
  font-weight: lighter; }

.allocations-download-card {
  background-color: initial; }

.allocations-right-side {
  padding: 20px 10px 20px; }

@media (min-width: 768px) and (max-width: 1023px) {
  .allocations-title {
    margin-left: 0; }
  .allocations-card-width {
    width: 85%; } }

@media (min-width: 1024px) {
  .allocations-title {
    text-align: left;
    width: 70%; }
  .allocations-card-width {
    width: 55%;
    margin-left: 10%; }
  .allocations-title-switch, .allocations-document-right {
    margin-left: 10%; } }
