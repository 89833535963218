.menu {
  display: none; }
  @media (min-width: 1024px) {
    .menu {
      display: block;
      box-sizing: border-box;
      width: 70%;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 5%; }
      .menu__links {
        list-style-type: none;
        display: block;
        float: right;
        height: 70px;
        line-height: 50px;
        padding: 0;
        margin: 0; }
      .menu__link {
        width: auto;
        padding: 0;
        float: left; }
        .menu__link .title-item {
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          display: block;
          color: #262626;
          font-weight: bolder;
          float: left;
          padding: 20px 10px 0;
          border: none;
          text-decoration: none; }
          .menu__link .title-item:hover:after {
            left: 0;
            width: 100%; }
          .menu__link .title-item:after {
            content: " ";
            display: block;
            width: 0;
            height: 3px;
            background-color: white;
            text-decoration: none;
            position: absolute;
            left: 50%;
            bottom: 0;
            transition: all .2s;
            transition-delay: .1s; }
      .menu__wrapper {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
        box-sizing: border-box;
        width: 100%;
        background: white;
        border: 1px solid black;
        border-top: 3px solid black;
        border-radius: 20px;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 70px;
        z-index: 11;
        left: 75%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding: 2% 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 400px; }
        .menu__wrapper.fixed-height100 {
          height: 100px; }
        .menu__wrapper.fixed-height200 {
          height: 200px; }
        .menu__wrapper.fixed-height230 {
          height: 230px; }
        .menu__wrapper.fixed-height400 {
          height: 400px; }
        .menu__wrapper.fixed-height500 {
          height: 500px; }
        .menu__wrapper.fixed-height600 {
          height: 600px; }
        .menu__wrapper.active {
          visibility: visible;
          opacity: 1; } }
