.menu-admin {
  width: 350px;
  min-height: 100vh;
  background-color: white; }
  .menu-admin__list {
    list-style: none;
    width: 100%;
    background-color: #ffff;
    padding: 0;
    margin: 0; }
  .menu-admin__list-item {
    display: list-item;
    color: #52658d;
    width: 94px;
    height: 19px;
    font-family: inherit;
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    padding: 10px 0;
    width: 100%; }
  .menu-admin__list-item--submenu {
    padding: 6px 0 5px 50px;
    width: auto;
    height: auto; }
    .menu-admin__list-item--submenu a {
      color: #666666;
      text-decoration: none;
      font-size: 14px;
      text-decoration: none;
      transition: text-decoration 0.3s ease; }
      .menu-admin__list-item--submenu a:hover {
        text-decoration: underline; }
  .menu-admin .category-icon {
    display: flex;
    flex-direction: row;
    border: 1px solid #e1e5eb;
    padding: 1%;
    justify-content: center;
    cursor: pointer; }
    .menu-admin .category-icon .category-name-icon {
      padding: 5%;
      position: relative;
      bottom: 5px;
      object-fit: contain;
      width: 20px; }
  .menu-admin .category-icon:hover {
    background-color: #f0f2f5; }
  .menu-admin .admin-header {
    width: 350px;
    height: 110px;
    background-image: url("../../../assets/images/bannerImages/background-image-admin.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    box-sizing: content-box; }
    .menu-admin .admin-header .admin-title-header {
      width: 226px;
      height: 28px;
      font-family: inherit;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding-top: 5%; }
    .menu-admin .admin-header .symbol-town {
      width: 50px;
      height: 80px;
      padding-top: 3%; }
