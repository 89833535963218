#pug-individual-card {
  margin-top: 0; }

.pug-page__card {
  margin-top: 0;
  padding: 0 20px !important; }

.pug-page__title-section {
  text-align: center;
  padding: 15px 0 3px;
  margin: 0 auto;
  font-size: 22px;
  border-bottom: 1px solid #2386b2;
  width: 90%; }

@media (min-width: 768px) and (max-width: 1023px) {
  .pug-page__graphic-documentation {
    display: flex;
    flex-direction: row; }
  .pug-page__individual-card {
    width: 85% !important; }
  .pug-page__title-section {
    margin: 20px auto 10px !important;
    width: 50%; } }

@media (min-width: 1024px) {
  .pug-page__graphic-documentation {
    display: flex;
    flex-direction: row;
    width: 100%; }
  .pug-page__left-side, .pug-page__right-side {
    width: 30%; }
  .pug-page__right-side {
    margin-left: 60px; }
  .pug-page__individual-card {
    width: 85% !important; }
  .pug-page__graphic-documentation {
    width: 100%; }
  .pug-page__title-section {
    margin: 20px 200px 10px !important;
    width: 55%; } }

.pug-page #pug-side-title {
  margin-left: 35px; }
