.sa-left-side {
  padding: 0; }

.sa-title {
  color: #fff; }

.sa-right-side {
  padding: 20px; }

.sa-documents-title {
  margin-bottom: 0; }

.sa-cards {
  margin-top: 0; }

#cards-wrapper {
  margin-top: 20px; }

@media (min-width: 768px) and (max-width: 1023px) {
  #sa-required-width {
    width: 100%; }
  .sa-wrapper {
    margin-top: 0;
    padding: 0; }
  .sa-families-title {
    margin-left: 0; }
  .sa-documents-title {
    margin-top: 30px; } }

@media (min-width: 1024px) {
  #sa-required-width {
    width: 100%; }
  .sa-wrapper {
    margin-top: 0;
    padding: 0; }
  .sa-documents-title {
    margin-left: 20%; }
  .sa-left-side {
    width: 60%; }
  .sa-families-title {
    margin-left: 0; }
  .sa-cards {
    margin: 15%;
    width: 50%; }
  .sa-list {
    margin-left: 15%; } }
