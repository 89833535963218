.qr-code-page__steps {
  padding: 0 10%; }

.qr-code-page__title-steps {
  font-size: 20px;
  padding: 10px; }

.qr-code-page__step {
  padding: 5px; }

.qr-code-page__sub-step {
  padding-left: 25px; }

.qr-code-page__code {
  min-height: 200px;
  text-align: center;
  padding: 20px; }

.qr-code-page__form {
  padding: 20px 0; }

.qr-code-page__activated {
  text-align: center; }
