.aquisition__date-wrapper {
  margin: 10px 0 10px 15%; }

.aquisition__date-input {
  width: 85%; }

@media (min-width: 768px) and (max-width: 1023px) {
  .aquisition__date-wrapper {
    margin-left: 11%; }
  .aquisition__date-input {
    width: 80%; } }

@media (min-width: 1024px) {
  .aquisition__date-wrapper {
    margin-left: 10%; }
  .aquisition__date-input {
    width: 78%; } }

#acquisition-search-img {
  position: relative;
  right: 60px; }

@media (min-width: 768px) and (max-width: 1023px) {
  #aquisitions-search-area {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%; }
  #aquisition-input {
    margin-left: 20%; }
  #aquisition-input-box {
    width: 55%; } }

@media (min-width: 1024px) {
  #aquisitions-search-wrapper {
    display: flex;
    flex-direction: row; }
  #aquisitions-search-area {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%; }
  #aquisition-input {
    margin-left: 22%; } }
