@import url("https://fonts.googleapis.com/css?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css?family=Vollkorn&display=swap");
@import './media-queries.scss';
@import './StandardPageProjects.scss';

h1,h2,h3, li{
    font-family: 'Vollkorn', serif;
    margin:0;

}
h4,h5, p{
    font-family: 'Ubuntu', sans-serif;
    margin:0;
}
p{
    font-size: 16px;
}
#programs-cards{
    margin-top: 0;
}
.standard{
    scroll-behavior: smooth;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    &__title-box{
        display: flex;
        flex-direction: row;

    }
    &__first-section{
        background-image:url('../assets/images/generalPresentation/pattern.png');
        padding: 20px 20px 30px;
    }
    &__generic{
        font-size: 14px;
        line-height: 1.71;
        color: #605b71;
    }
    &__title-page{
        color: #0d0820;
    }
    &__main-title{
        font-size: 25px;
        font-weight: 600;
        letter-spacing: -0.5px;
        color: #0d0820;
        margin-top: 8px;
    }
    &__card{
        background-color: #fff;
        font-size: 20px;
        color: #0d0820;
        padding:25px 20px 10px 20px;
        margin-top:20px;
        height: auto;
    }
    &__card-title{
        font-size: 18px;
        margin-bottom:5px;
    }
    &__ordinary-title{
        font-size: 16px;
    }
    &__card-description{
        font-size: 14px;
        line-height: 1.5;
        color: #3d394d;
        text-align: justify;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    &__card-description-empty{
        display: none;
    }
    &__card-description-details{
        font-size: 14px;
        line-height: 1.5;
        color: #3d394d;
        text-align: justify;
        white-space: wrap;
        overflow: auto;
    }
    &__general-description{
        white-space: normal;
        overflow: initial;
        text-overflow: initial;
        text-align: initial;
        text-indent: 40px;
        width: 90%;
    }
    &__card-responsable{
        font-size: 14px;
        font-size: 400;
        padding-bottom: 5px;
        color: #404040;
      }
    &__button{
        width: 100%;
        height: 45px;
        border:none;
        background-color: #2386b2;
        cursor: pointer;
    }
    &__button-writing{
        color:#ffff;
        margin: auto!important;
    }
    &__arrow-icon{
        width:20px;
    }
    &__search-icon{
        width:16px;
        cursor: pointer;
    }
    &__input-box{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &__input{
        border:none;
        border-bottom:1px solid #e0eaee;
        text-indent: 3px;
        font-family: Ubuntu;
        height:25px;
    }
    input::placeholder{
        opacity: 0.8;
        font-family: Ubuntu;
        font-size: 14px;
        text-indent: 3px;
        color: #0d0820;
    }
    &__wrapper{
        padding:15px;
        margin-top: 10px;
    }
    &__mp-input-box{
        margin-bottom:20px;
    }
    &__mp-input{
        width:85%;
    }
    &__mp-icon{
        right: 25px;
        width: 15px;
    }
    &__cards{
        margin-top: 30px;
        display: flex;
        flex-direction: column;

    }
    &__individual-card{
        border: solid 1px #c4cacd;
        padding: 10px;
        margin: 10px;
        position: relative;
        min-height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }
    &__card-title{
        font-size: 16px;
        letter-spacing: -0.25px;
        color: #0d0820;
        margin-bottom: 0px;
        width: 85%;
    }
    &__card-date{
        font-size: 13px;
        font-weight: bolder;
        line-height: 1.85;
        letter-spacing: 1px;
        color: #605b71;
        margin-bottom: 10px;
      }
    &__card-img{
        width:22px;
        position: absolute;
        right: 14px;
        cursor: pointer;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    &__wrapper-no-search{
        margin-top: 0;
        padding-top: 0;
    }

    &__educational-stimulents{
        margin-top: 0;
    }
    &__list-item{
        padding:5px 0;
        font-weight: bolder;
        color:#262626;
    }
    &__death-record-wrapper{
        padding:10px 20px;
    }
    &__death-record-title{
        margin:15px 0 5px;
    }
    &__description-right-side{
        color:#ffff;
        font-weight: lighter;
        font-size: 16px;
    }
    &__unordered-document-list{
        padding: 0;
        margin:0 25px;
        li{
            color:#404040;
        }
    }
    &__civil-status-info{
        margin:10px 0;
    }
    &__civil-status-title{
        border-bottom: 1px solid #2386b2;
        width:90%;
        margin-left: 10px;
    }
    &__number-box{
        position: absolute;
        top:0;
        right: 0;
        background-color: #000033;
    }
    &__dispozition-number{
        color:#fff;
        padding:3px;
        font-weight: lighter;
    }.MuiNativeSelect-select{
        border-bottom: 1px solid #e0eaee;
        font-family: Ubuntu;
        font-weight: lighter;
    }
    .MuiNativeSelect-select:focus{
        background:transparent;
    }
    &__input:focus{
        outline:none;
    }

    @include tablet {
        &__first-section{
            padding: 30px 20px 30px 100px;
        }
        &__cards{
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
        &__individual-card{
            width: 35%;
            margin: 10px;
        }
        &__mp-input {
            width: 55%;
        }
        &__mp-input-box{
            margin:10px 95px 20px;
        }
        &__death-record-wrapper{
            padding:10px 50px;
        }
        &__civil-status-title{
           width:70%;
        }
        &__search-general-area{
            margin-left: 10%;
        }
      }


    @include desktop {
        &__taxes-link{
            text-decoration: none;
        }
        &__search-archive{
            display: flex;
            flex-direction: row;
        }
        &__taxes{
            width:50%;
            font-size: 22px;
            padding:10px 0;
            font-weight: light;
        }
        &__first-section{
            padding: 50px 10% 55px;
        }
        &__cards{
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            width:80%;
            margin:40px 0 30px 10%;
        }
        &__wrapper{
            min-height: 600px;
        }
        &__generic{
            font-size: 16px;
        }
        &__individual-card{
            width: 25%;
            margin: 10px;
        }
        &__mp-input {
            width: 45%;
        }
        &__mp-input-box{
            margin:10px 10% 20px;
        }
        &__educational-stimulents{
            margin-top: 0;
        }
        &__wrapper-ed-stimulents{
            display: flex;
            margin-left:5%;
        }
        &__individual-ed-stimulents{
            width:30%;
        }
        &__document-list{
            margin-left: 10%;
        }
        &__child-allowances-title{
            margin-left: 100px!important;
        }
        &__death-record-wrapper{
            padding:10px 200px 30px;
        }
        &__list-item{
            font-size: 18px;
        }
        &__card-description{
            font-size: 16pxș
        }
        &__death-record-title{
            font-size: 18px;
        }
        p{
            font-size: 18px;
        }
        &__mariage-record-left{
            margin-left: 10%;
        }
        &__civil-status-title{
            width:35%;
         }
         &__common-title{
             margin-left: 10%!important;
         }
         #programs-cards{
            margin-left: 40px;
            width: 95%;
        }
        #programs-title{
           margin-left: 20%;
        }
        #concilors-search-button{
            margin:14px 0;
        }
        #educational-stimulents-title{
            margin-left: 30%;
        }
        #required-width-ed-stimulents{
            width:100%;
        }
        #left-main-title{
            margin-left: 20%;
        }
      }

    }
