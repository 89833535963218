.hamburger__link {
  border-bottom: 1px solid lightgrey;
  text-align: left;
  padding: 0 20px;
  height: 60px; }
  .hamburger__link a {
    display: block;
    line-height: 60px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: black;
    cursor: pointer; }
    .hamburger__link a .link-title {
      display: inline-block;
      vertical-align: middle;
      line-height: 1em; }

.hamburger__link--menu {
  height: 60px;
  line-height: 60px;
  width: 100%;
  color: grey; }

.hamburger__right-icon, .hamburger__left-icon {
  width: 20px;
  line-height: 50px;
  margin-top: 20px; }

.hamburger__right-icon {
  float: right;
  opacity: .3; }

.hamburger__left-icon {
  float: left;
  opacity: .3;
  margin-right: 5px; }
