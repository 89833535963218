.birth-registration__page {
  background-image: url("../../assets/images/generalPresentation/pattern.png"); }

.birth-registration__first-section {
  padding: 20px; }

.birth-registration__main-title {
  font-size: 22px; }

.birth-registration__card-title {
  display: flex;
  flex-direction: row;
  position: relative; }

.birth-registration__arrow-icon {
  width: 18px;
  position: absolute;
  top: 3px; }

.birth-registration__cards {
  display: flex;
  flex-direction: column; }

.birth-registration__card {
  margin: 10px 20px; }

.birth-registration__button {
  height: 30px; }

.birth-registration__content {
  padding: 10px 20px; }

.birth-registration__background {
  background-color: #e0eaee;
  padding: 10px 20px; }

.birth-registration__document-box {
  margin: 10px auto; }

.birth-registration__item {
  padding: 3px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #3d394d; }

.birth-registration__info-box {
  border: 1px solid  #e0eaee;
  padding: 10px;
  margin-bottom: 10px; }

.birth-registration__info-box-light {
  background-color: #ffff; }

.birth-registration__child-transcripts {
  display: flex;
  flex-direction: column; }

.birth-registration__child-type {
  padding: 30px 20px; }

.birth-registration__switch-dark {
  background-color: #0d0820; }

.birth-registration__switch {
  color: #ffff;
  font-weight: lighter; }

.birth-registration__child-transcript-info, .birth-registration__switch {
  margin: 5px 0; }

.birth-registration__documents-info {
  padding: 5px 0; }

.birth-registration__second-list li {
  margin: 5px 0;
  color: #3d394d; }

@media (min-width: 768px) and (max-width: 1023px) {
  .birth-registration__card-title {
    min-height: 50px; }
  .birth-registration__card {
    width: 90%;
    margin: 10px 25px; }
  .birth-registration__documents-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0; }
  .birth-registration__document-box {
    width: 30%;
    margin: 10px; }
  .birth-registration__info-box {
    border: 1px solid  #f02020; }
  .birth-registration__child-transcripts {
    flex-direction: row; }
  .birth-registration__child-type {
    width: 50%; } }

@media (min-width: 1024px) {
  .birth-registration__cards {
    flex-direction: row;
    padding-left: 9%; }
  .birth-registration__card {
    width: 25%;
    margin: 20px; }
  .birth-registration__content {
    padding-left: 10%; }
  .birth-registration__documents-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0; }
  .birth-registration__document-box {
    width: 30%;
    margin: 10px; }
  .birth-registration__info-box {
    border: 1px solid  #f02020;
    margin-right: 10%;
    padding: 20px 10px; }
  .birth-registration__ordinary-title {
    color: #f02020; }
  .birth-registration__background {
    padding: 20px 10%; }
  .birth-registration__child-transcripts {
    margin: 0 10%;
    flex-direction: row; }
  .birth-registration__child-type {
    width: 50%; }
  .birth-registration__second-list {
    width: 80%; }
    .birth-registration__second-list li {
      margin: 10px 0; } }
