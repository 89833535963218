.newsletter {
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  padding-bottom: 180px;
  overflow: hidden; }
  .newsletter__title {
    margin: 0 auto;
    text-align: center;
    padding: 20px 0 0;
    margin-bottom: 10px; }
  .newsletter__subtitle {
    text-align: center;
    margin: 0 auto; }
  .newsletter__title-box {
    padding: 0 30px; }
  .newsletter__list {
    margin: 30px auto;
    font-family: sans-serif;
    list-style-type: none;
    padding-right: 20px; }
  .newsletter__item {
    margin: 10px 0; }
  .newsletter__link {
    text-decoration: none;
    color: #333333; }
  .newsletter__box {
    display: flex;
    flex-direction: row; }
  .newsletter__icon {
    width: 16px;
    padding: 5px;
    position: relative;
    bottom: 25px;
    right: 5px; }
  .newsletter__other-pages-icon {
    bottom: 2px; }

@media (min-width: 768px) {
  .newsletter__icon {
    bottom: 14px; } }

@media (min-width: 1024px) {
  .newsletter__list {
    width: 50%;
    padding-left: 50px; }
  .newsletter__icon {
    bottom: 5px; } }
