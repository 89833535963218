@import url("https://fonts.googleapis.com/css?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css?family=Vollkorn&display=swap");
.pergament {
  padding: 10px 25px;
  background-image: url("../../assets/images/generalPresentation/pergament.jpg"); }

.aa-description {
  padding: 0 20px; }

#environment-title {
  text-align: left;
  margin-left: 20px; }

@media (min-width: 768px) and (max-width: 1023px) {
  .pergament {
    padding: 20px 40px; }
  #environment-title {
    margin-left: 80px; } }

@media (min-width: 1024px) {
  #environment-title {
    margin-left: 14%; }
  .pergament {
    width: 60%;
    margin-left: 0;
    padding: 10px 20px; }
  #gp-description {
    width: 100%; }
  #pergament-box {
    padding: 0; }
  #gp-subtitle {
    text-align: left;
    margin: 20px 12px 10px; }
  #gp-description {
    font-size: 16px;
    text-indent: 0; }
  .aa-description {
    width: 60% !important;
    padding: 10px 12% 20px; }
  .dd-description {
    width: 90% !important; }
  .aa-info {
    width: 90% !important; }
  #aa-banner-img {
    width: 100%;
    padding: 0;
    margin-top: 0; }
  #aa-close-modal {
    top: 0;
    right: 0; } }

.about-vicov__close-modal {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: #ffff; }
  .about-vicov__close-modal img {
    width: 16px;
    height: 16;
    padding: 3px; }

.about-vicov__banner {
  position: relative;
  padding: 0;
  margin: 0; }

.about-vicov__banner-img {
  height: 350px;
  object-fit: contain; }

.about-vicov__light-section {
  padding: 0 20px; }

.about-vicov__dark-section {
  background-color: #e0eaee;
  width: 90%;
  padding: 10px 20px; }

.about-vicov__header {
  width: 100%;
  position: relative; }

.about-vicov__header-image img {
  display: none; }

.about-vicov__left-content, .about-vicov__right-content {
  width: 100%; }
  .about-vicov__left-content h3, .about-vicov__right-content h3 {
    text-align: center; }
  .about-vicov__left-content h4, .about-vicov__right-content h4 {
    text-indent: 30px;
    font-size: 14px;
    margin: 0 20px; }
  .about-vicov__left-content h5, .about-vicov__right-content h5 {
    font-weight: normal;
    margin: 0 20px;
    padding: 5px 0;
    text-indent: 50px;
    font-weight: bolder; }

.about-vicov__content {
  position: relative;
  width: 100%; }

.about-vicov__container-inside {
  background-color: #ffff;
  position: relative; }

@media (min-width: 768px) and (max-width: 1023px) {
  .about-vicov__banner-img {
    width: 100%;
    object-fit: cover;
    height: 300px; }
  .about-vicov__close-modal img {
    width: 20px;
    height: 20PX;
    padding: 3px; }
  .about-vicov__light-section {
    padding-left: 40px; }
  .about-vicov__dark-section {
    padding-left: 40px;
    width: 100%; } }

@media (min-width: 1024px) {
  .about-vicov__container {
    background-color: rgba(13, 8, 32, 0.9); }
  .about-vicov__container-inside {
    margin: 0 12%; }
  .about-vicov__banner-img {
    width: 65%;
    object-fit: cover;
    height: 400px;
    margin-top: 40px;
    padding-left: 12%; }
  .about-vicov__close-modal {
    top: 9%;
    right: 23%; }
    .about-vicov__close-modal img {
      width: 20px;
      height: 20PX;
      padding: 3px; }
  .about-vicov__light-section {
    margin: 0 12%;
    width: 63%;
    padding: 10px 20px;
    background-color: #ffff; }
  .about-vicov__dark-section {
    margin: 0 12%;
    width: 63%; }
  .about-vicov__header-image {
    width: 40%;
    display: inline-block;
    margin: 20px; }
    .about-vicov__header-image img {
      width: 300px;
      height: auto;
      border-radius: 30px;
      margin-left: auto;
      margin-right: auto;
      display: block; }
  .about-vicov__content:after {
    content: "";
    position: absolute;
    height: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #2386b2;
    opacity: 0.5;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%); }
  .about-vicov__left-content, .about-vicov__right-content {
    width: 50%;
    display: inline-block;
    vertical-align: text-top; } }

.table {
  width: 90%;
  margin: 20px auto;
  font-size: 14px; }
  .table__row {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 2px solid #e0e0e0;
    border-left: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
    border-collapse: collapse;
    padding: 3px 0; }
    .table__row .text {
      flex: 1 1 100px;
      flex-grow: 1;
      padding-right: 1px;
      word-wrap: break-word;
      min-width: 40px;
      width: 0;
      text-align: center;
      font-family: 'Ubuntu', sans-serif;
      font-weight: bolder; }
      .table__row .text--final {
        font-weight: bold; }
    .table__row--header {
      font-weight: bold;
      padding: 8px 0;
      border-top: 2px solid #e0e0e0; }
    .table__row--green {
      background-color: #edf3f5; }
  .table__subrow {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: no-wrap;
    width: 100%;
    margin-top: 5px;
    padding: 3px 0; }
    .table__subrow .text {
      min-width: 30px;
      width: 50px; }
    .table__subrow--header {
      border-top: 2px solid #e0e0e0; }
