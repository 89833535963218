.admin-page {
  display: flex;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  background-image: url("../../../assets/images/bannerImages/background-blue.svg");
  background-repeat: no-repeat;
  background-size: cover; }
  .admin-page__left-content {
    width: 350px; }
  .admin-page__right-content {
    padding: 30px;
    width: 100%;
    max-width: 1400px; }
  .admin-page .paper {
    margin-top: 50px; }
    .admin-page .paper .MuiPrivateTabIndicator-colorPrimary {
      background-color: #fac756;
      height: 5px; }
  .admin-page .card {
    background-color: #ffff;
    border: 1px solid #e0e2e8;
    border-radius: 8px; }
  .admin-page .admin-switch-title {
    font-family: 'Roboto', sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: normal;
    color: #16233f;
    margin: 0 50px 0;
    padding-bottom: 10px;
    width: 170px; }
  .admin-page .admin-switch-title:hover, .admin-page .admin-switch-title:active, .admin-page .admin-switch-title:focus {
    border-bottom: 6px solid #fac756;
    border-radius: 3px 3px; }
  .admin-page__profile-content {
    margin: 3% 1px; }
  .admin-page .label-title-provisions {
    margin: 0;
    padding: 15px 0 10px; }
  .admin-page .admin-page-header {
    display: flex;
    justify-content: space-between; }
  .admin-page textarea {
    height: 100%; }
  .admin-page .admin-long-title {
    width: auto !important; }
  .admin-page .log-out-button {
    margin-top: 20px;
    width: 110px;
    font-size: 13px; }
  .admin-page__profile-title {
    font-family: inherit;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #16233f;
    padding: 20px 0 0 60px;
    margin-bottom: 0; }
  .admin-page__soon {
    height: 150px;
    background-color: #f2f3f5;
    width: 90%;
    margin: 3% auto;
    position: relative;
    border-radius: 30px; }
    .admin-page__soon p {
      font-size: 18px;
      font-weight: bold;
      margin: auto;
      padding-top: 50px;
      text-align: center; }

.general-button {
  border: none;
  border-radius: 5.7px;
  background-color: #2979c6;
  width: 129px;
  height: 35px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: -0.11px;
  text-align: center;
  color: #ffffff;
  text-transform: upper-case;
  cursor: pointer;
  align-items: center; }

.form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 20px; }

.submit-button {
  display: flex;
  justify-content: flex-start;
  margin-right: 200px; }

.reset-button {
  margin-left: 200px; }

.profile-title-specific {
  padding: 20px 0 0 49px; }
