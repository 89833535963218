.contact-modal__info-box {
  display: flex;
  flex-direction: row;
  padding: 10px 20px; }

.contact-modal__title {
  margin-left: 20px;
  padding-bottom: 20px; }

.contact-modal__general-info {
  width: 20%;
  color: #4d4d4d; }

.contact-modal__specific-info {
  color: #262626;
  width: 100%; }
