.info-page {
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  padding-bottom: 180px;
  overflow: hidden; }

.info-form {
  padding: 0 auto 40px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center; }
  .info-form__field-box {
    width: 75%;
    display: flex;
    flex-direction: column;
    padding: 0 50px 10px 10px; }
  .info-form__field {
    height: 40px;
    border-radius: 4.3px;
    border: solid 0.7px #becad6;
    background-color: #ffffff;
    font-size: 16px;
    padding-left: 10px; }
  .info-form__textarea {
    height: 70px; }
  .info-form__label {
    text-indent: 10px;
    margin: 0;
    padding: 20px 0 5px;
    font-family: sans-serif;
    font-weight: normal; }
  .info-form__indication {
    padding: 30px 20px 20px;
    font-weight: bold;
    text-indent: 15px; }
  .info-form__accept-conditions-box {
    display: flex;
    flex-direction: row; }
  .info-form__accept-conditions {
    position: relative;
    top: 20px;
    left: 5px; }
  .info-form__attachement-field {
    width: 80%; }
  .info-form__button {
    width: 150px;
    height: 30px;
    margin: 15px auto;
    cursor: pointer;
    border: none;
    border-radius: 5.7px;
    background-color: #2979c6; }
    .info-form__button:disabled {
      cursor: not-allowed; }
  .info-form__button-writing {
    font-size: 14px;
    margin: 0;
    padding: 5px;
    font-family: sans-serif;
    color: #ffff;
    font-weight: light; }
  .info-form__note {
    color: black; }
  .info-form input {
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: bolder;
    color: #0c0624;
    text-indent: 5px; }
  .info-form__actions-box {
    display: flex;
    flex-direction: column;
    border-top: solid 1px #becad6;
    padding-top: 20px;
    width: 80%;
    margin: 0 auto; }
  .info-form__actions-button {
    width: 60%;
    margin: 10px auto;
    border: solid 1px #becad6;
    background-color: #e6e6ff;
    cursor: pointer; }
  .info-form__action-button-writing {
    padding: 5px 0;
    color: #000033; }

@media (min-width: 768px) {
  .info-form {
    width: 60%;
    margin-left: 5%; }
    .info-form__indication {
      width: 60%;
      margin: 0 auto;
      text-indent: 0;
      margin-left: 5%; }
    .info-form__actions-box {
      width: 50%;
      margin-left: 5%; }
    .info-form__actions-button {
      width: 70%;
      margin: 10px 0; }
    .info-form__action-button-writing {
      padding: 10px 0; } }

@media (min-width: 1024px) {
  .info-form {
    width: 50%;
    margin-left: 10%; }
    .info-form__field-box {
      width: 100%; }
    .info-form__indication {
      width: 40%;
      padding-top: 40px;
      font-size: 18px;
      margin-left: 10%; }
    .info-form__field {
      height: 50px; }
    .info-form__textarea {
      height: 120px; }
    .info-form__button {
      width: 170px;
      height: 40px;
      margin: 30px auto 40px; }
    .info-form__button-writing {
      font-size: 15px; }
    .info-form input {
      font-size: 18px; }
    .info-form__actions-box {
      width: 50%;
      margin-left: 10%;
      padding-top: 40px;
      flex-direction: row;
      justify-content: space-between; }
    .info-form__actions-button {
      width: 30%;
      margin: 10px 0; }
    .info-form__action-button-writing {
      padding: 10px 0;
      font-size: 14px; } }
