.delete-modal__title {
  font-size: 18px;
  margin-bottom: 20px; }

.delete-modal__id-field {
  margin: 10px auto;
  padding: 10px;
  width: 90%;
  border: solid 1px #becad6;
  border-radius: 5px; }

.delete-modal__id-field::placeholder {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: bolder;
  color: #0c0624; }

.delete-modal__solution {
  margin-top: 20px; }

@media (min-width: 768px) and (max-width: 1023px) {
  .delete-modal__id-field {
    width: 280px; } }

@media (min-width: 1024px) {
  .delete-modal {
    padding: 20px 30px 50px;
    margin: 0 auto; }
    .delete-modal__id-box {
      justify-content: space-between;
      margin-top: 30px; }
    .delete-modal__id-field {
      width: 280px;
      margin-top: 20px; }
    .delete-modal__solution {
      margin-top: 40px;
      border: solid 1px #becad6;
      padding: 10px 0;
      width: 40%;
      text-align: center; }
    .delete-modal__recapcha {
      width: 100%;
      margin-top: 20px; }
    .delete-modal__submit {
      margin: 20px auto; } }
