.header {
  position: relative;
  height: 70px;
  background-color: #ffff; }
  .header__logo-img {
    width: 30px;
    height: auto;
    padding-right: 5px; }
  .header__logo-box {
    display: flex;
    flex-direction: row;
    padding: 10px 15px 10px 10px;
    justify-content: center; }
  .header__link {
    text-decoration: none;
    cursor: pointer; }
  .header__city-name {
    display: flex;
    flex-direction: column;
    padding-top: 10px; }
  .header__institution {
    color: #2386b2;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 3px; }
  .header__institution-name {
    color: #0d0820;
    margin: 0;
    font-weight: bold;
    font-size: 15px; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .header {
      height: 70px; }
      .header__logo-box {
        justify-content: start;
        margin-left: 80px; } }
  @media (min-width: 1024px) {
    .header {
      height: 90px;
      z-index: 11; }
      .header__logo-box {
        justify-content: start;
        margin-left: 10%; }
      .header__logo-img {
        width: 40px;
        padding-right: 15px; }
      .header__institution {
        padding-top: 5px; }
      .header__institution-name {
        font-size: 18px; } }
