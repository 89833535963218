#permits-page__left-side {
  padding: 0; }

#permits-card {
  margin: 10px 10px 10px 20px; }

#permits-card-title {
  color: #ffff; }

#permits-main-title {
  border-bottom: 1px solid #2386b2;
  width: 70%; }

#permits-question {
  border: 1px solid #ffff; }

#permits-answer {
  color: #00001a; }

#pug-questions-button {
  background-color: #0d0820;
  border: 1px solid #ffff;
  margin: 0 auto;
  display: flex; }

#pug-button-writing {
  color: #ffff;
  font-weight: lighter;
  padding: 5px 10px; }

#questions-title {
  color: #ffff;
  font-weight: lighter;
  display: flex;
  justify-content: center; }

#permits-link {
  text-decoration: none;
  cursor: pointer; }

@media (min-width: 768px) and (max-width: 1023px) {
  #permits-side-title {
    margin-left: 35px; }
  #permits-card {
    width: 60%; }
  #permits-cards {
    justify-content: left;
    margin-left: 30px; }
  #permits-main-title {
    width: 50%; } }

@media (min-width: 1024px) {
  #permits-card {
    width: 50%; }
  #permits-cards {
    justify-content: left;
    margin-left: 160px !important; }
  #permits-questions-wrapper {
    padding-top: 30px; } }
