.solicitation-modal__info-box {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  justify-content: flex-start; }

.solicitation-modal__title {
  margin-left: 20px;
  padding-bottom: 20px; }

.solicitation-modal__general-info {
  width: 20%;
  color: #4d4d4d; }

.solicitation-modal__specific-info {
  color: #262626;
  width: 100%; }

.solicitation-modal__document-button {
  margin-left: 17%; }

.solicitation-modal__status {
  width: 20%; }

.solicitation-modal__button {
  margin-left: 30px; }
