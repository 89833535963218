.solicitations-table {
  margin-top: 30px; }

.solicitations-id {
  cursor: pointer;
  color: #000066;
  text-decoration: underline; }

.solicitations-container {
  background-color: #ffff;
  padding: 30px 0; }
