.submenu__column {
  padding: 0 5%;
  width: 40%; }

.submenu__title {
  font-weight: bold;
  line-height: 40px; }
  .submenu__title a {
    text-decoration: none;
    color: black;
    cursor: pointer; }

.submenu__links {
  list-style-type: none;
  line-height: 50px;
  padding: 0;
  margin: 0; }

.submenu__link {
  line-height: 30px;
  padding: 0;
  margin: 0; }
  .submenu__link a {
    text-decoration: none;
    color: black;
    cursor: pointer; }

.columnSeparator {
  position: absolute;
  background-color: black;
  top: 10%;
  right: 50%;
  height: 80%;
  width: 1px; }
