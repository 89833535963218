.lead-page__input-box {
  justify-content: center;
  margin-top: 5px; }

@media (min-width: 768px) and (max-width: 1023px) {
  .lead-page__search-area {
    width: 90% !important; }
  .lead-page__search-button {
    margin: 5px !important; } }

@media (min-width: 1024px) {
  .lead-page__input-box {
    margin-top: 10px; } }
