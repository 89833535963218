.login-form {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
  padding: 3% 0; }
  .login-form__title {
    color: '#4267B2'; }
  .login-form__field {
    width: 80%;
    margin: 0 auto; }
  .login-form__submit {
    margin-top: 20px; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .login-form {
      width: 60%; } }
  @media (min-width: 1024px) {
    .login-form {
      width: 50%; } }
