#certificates-main-title {
  border-bottom: 1px solid #2386b2; }

@media (min-width: 768px) and (max-width: 1023px) {
  #certificates-main-title {
    width: 90%;
    margin-left: 30px; } }

@media (min-width: 1024px) {
  #certificate-download-cards {
    margin-left: 160px;
    width: 50%; }
  #certificate-cards {
    margin-left: 30px; } }
