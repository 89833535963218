.event-modal__right-section {
  position: relative; }

.event-modal__close-modal {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: #ffff;
  padding: 5px; }

.event-modal__image {
  height: 350px;
  width: 100%;
  object-fit: cover; }

.event-modal__content {
  padding: 10px;
  background-color: #ffff; }

.event-modal__title {
  font-size: 24px;
  letter-spacing: -0.25px;
  color: #0d0820; }

.event-modal__timeline {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.85;
  letter-spacing: 1px;
  color: #605b71;
  margin-bottom: 10px; }

.event-modal__description {
  font-size: 16px;
  line-height: 1.5;
  color: #3d394d; }

@media (min-width: 1024px) {
  .event-modal {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 auto; }
    .event-modal__image {
      height: auto;
      width: 100%; }
    .event-modal__right-section {
      width: 50%; }
    .event-modal__title, .event-modal__timeline {
      text-align: center; }
    .event-modal__description {
      width: 70%;
      margin: 0 auto; }
    .event-modal__content {
      padding: 20px 0 0 20px;
      margin: 20px auto;
      width: 50%; } }
