.accessibility {
  display: flex;
  position: fixed;
  right: 0;
  top: 20%;
  list-style: none;
  z-index: 1; }
  @media (min-width: 1024px) {
    .accessibility {
      top: 35%; } }
  .accessibility__icon {
    width: 45px;
    margin: auto;
    cursor: pointer; }
  .accessibility__menu-list {
    list-style-type: none;
    border: 1px solid black;
    padding: 0;
    width: 0;
    transition: width .5s ease-in; }
  .accessibility__menu-list--visible {
    width: 100%; }
  .accessibility__menu-list--item {
    background-color: white;
    display: flex;
    justify-content: start;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid grey;
    cursor: pointer; }
    .accessibility__menu-list--item a {
      padding-left: 5px; }

body.accessibility-highlighted-links a, body.accessibility-highlighted-links button {
  text-decoration: underline; }

body.accessibility-normal-font {
  font-family: "Times New Roman", Times, serif; }

body.accessibility-black-and-white {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }

body.accessibility-invert-colors {
  filter: invert(100%); }

body.accessibility-font-size-40 {
  font-size: 40%; }
  body.accessibility-font-size-40 span, body.accessibility-font-size-40 td, body.accessibility-font-size-40 th, body.accessibility-font-size-40 select, body.accessibility-font-size-40 input, body.accessibility-font-size-40 h2, body.accessibility-font-size-40 h3, body.accessibility-font-size-40 h4, body.accessibility-font-size-40 h5 {
    font-size: 40% !important; }

body.accessibility-font-size-60 {
  font-size: 60%; }
  body.accessibility-font-size-60 span, body.accessibility-font-size-60 td, body.accessibility-font-size-60 th, body.accessibility-font-size-60 select, body.accessibility-font-size-60 input, body.accessibility-font-size-60 h2, body.accessibility-font-size-60 h3, body.accessibility-font-size-60 h4, body.accessibility-font-size-60 h5 {
    font-size: 60% !important; }

body.accessibility-font-size-80 {
  font-size: 80%; }
  body.accessibility-font-size-80 span, body.accessibility-font-size-80 td, body.accessibility-font-size-80 th, body.accessibility-font-size-80 select, body.accessibility-font-size-80 input, body.accessibility-font-size-80 h2, body.accessibility-font-size-80 h3, body.accessibility-font-size-80 h4, body.accessibility-font-size-80 h5 {
    font-size: 80% !important; }

body.accessibility-font-size-120 {
  font-size: 120%; }
  body.accessibility-font-size-120 span, body.accessibility-font-size-120 td, body.accessibility-font-size-120 th, body.accessibility-font-size-120 select, body.accessibility-font-size-120 input, body.accessibility-font-size-120 h2, body.accessibility-font-size-120 h3, body.accessibility-font-size-120 h4, body.accessibility-font-size-120 h5 {
    font-size: 120% !important; }

body.accessibility-font-size-140 {
  font-size: 140%; }
  body.accessibility-font-size-140 span, body.accessibility-font-size-140 td, body.accessibility-font-size-140 th, body.accessibility-font-size-140 select, body.accessibility-font-size-140 input, body.accessibility-font-size-140 h2, body.accessibility-font-size-140 h3, body.accessibility-font-size-140 h4, body.accessibility-font-size-140 h5 {
    font-size: 140% !important; }

body.accessibility-font-size-160 {
  font-size: 160%; }
  body.accessibility-font-size-160 span, body.accessibility-font-size-160 td, body.accessibility-font-size-160 th, body.accessibility-font-size-160 select, body.accessibility-font-size-160 input, body.accessibility-font-size-160 h2, body.accessibility-font-size-160 h3, body.accessibility-font-size-160 h4, body.accessibility-font-size-160 h5 {
    font-size: 160% !important; }
