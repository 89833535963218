.event-page__title {
  text-align: center;
  padding-top: 50px;
  color: #1D64F0; }

.event-page__content {
  margin-left: 5%; }

.event-form {
  padding: 2% 3%; }
  .event-form__title {
    text-align: center; }
  .event-form__field {
    text-align: center; }
  .event-form__field--dates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 20px; }
  .event-form__submit {
    margin-top: 20px;
    text-align: center; }
  .event-form__reset {
    margin-top: -40px;
    text-align: right; }
  .event-form__upload-content {
    position: relative;
    padding: 30px 50px; }
  .event-form__upload-files h3 {
    text-align: center; }
