.mariage-publication-admin__title {
  text-align: center;
  padding-top: 50px;
  color: #1D64F0; }

.mariage-publication-admin__content {
  margin-left: 5%; }

.mariage-form {
  padding: 2% 3%; }
  .mariage-form__title {
    text-align: center; }
  .mariage-form__field {
    text-align: center; }
  .mariage-form__group-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 20px; }
  .mariage-form__submit {
    margin-top: 20px;
    text-align: center; }
  .mariage-form__reset {
    margin-top: -40px;
    text-align: right; }
