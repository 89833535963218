body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  z-index: 1;
  height: 100%;
  position: relative; }
  body.has-active-menu .body_overlay {
    visibility: visible;
    transition: visibility 0s .3s; }
  body.ReactModal__Body--open {
    overflow: hidden; }
  body .body_overlay {
    position: fixed;
    background: #000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    opacity: .75;
    filter: alpha(opacity=60);
    visibility: hidden;
    transition: none;
    z-index: 10; }
  body .section-separator {
    margin: 30px auto;
    height: 1px;
    background-color: black;
    opacity: 0.3;
    width: 100%; }
