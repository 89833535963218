.events__wrapper, .events__titles {
  padding: 20px; }

.events__individual {
  margin-bottom: 25px; }

.events__titles {
  display: none;
  flex-direction: row; }

.events__title-section {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: #4d4d4d;
  margin-bottom: 5px;
  cursor: pointer; }

.events__title-active {
  color: #0d0820; }

.events__only-mobile {
  margin: 20px 20px 10px; }

.events__image {
  height: 250px;
  width: 100%;
  object-fit: cover; }

.events__date-container {
  position: relative; }

.events__date-box {
  width: 60%;
  height: 30px;
  background-color: #0d0820;
  position: absolute;
  bottom: 5px; }

.events__date {
  color: #ffff;
  font-weight: lighter;
  text-align: center;
  padding-top: 5px; }

.events__main-title {
  margin: 10px 0 5px;
  font-size: 22px; }

.events__description {
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  color: #3d394d; }

.events__see-more {
  margin: 5px 0;
  color: #2386b2;
  cursor: pointer; }

.events__no-events {
  border: 1px solid #bcc3c6;
  width: 70%;
  display: flex;
  margin: 10px auto; }

.events__no-description {
  padding: 5px 0;
  color: #0d0820;
  font-weight: light;
  text-align: center; }

@media (min-width: 768px) and (max-width: 1023px) {
  .events__only-mobile {
    display: none; }
  .events__titles {
    display: flex;
    justify-content: space-around;
    padding-left: 20px; }
  .events__title-section {
    margin: 0 10px; }
  .events__wrapper {
    display: flex;
    margin: 0 20px;
    flex-direction: row;
    flex-wrap: wrap; }
  .events__individual {
    width: 44%;
    margin: 10px 20px; }
  .events__no-events {
    width: 40%; } }

@media (min-width: 1024px) {
  .events__only-mobile {
    display: none; }
  .events__image {
    height: 300px; }
  .events__titles {
    display: flex;
    justify-content: space-around;
    width: 50%;
    padding-left: 150px; }
  .events__title-section {
    margin: 0 10px;
    font-size: 24px; }
  .events__wrapper {
    display: flex;
    margin: 0 160px;
    flex-direction: row;
    flex-wrap: wrap; }
  .events__individual {
    width: 30%;
    margin: 10px 20px; }
  .events__no-events {
    width: 30%;
    margin-bottom: 30px; }
  .events__no-description {
    margin: 0 auto; } }
