.presentation-box {
  position: relative;
  list-style-type: none;
  width: 49.5%;
  float: left;
  outline: 1px solid transparent; }
  .presentation-box__link {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    cursor: pointer; }
  .presentation-box__link--title {
    font-size: 0.75rem;
    position: absolute;
    top: 40%;
    right: 0;
    left: 0;
    margin: 0 auto; }
  .presentation-box__link--image image {
    width: 250px;
    min-height: 250px;
    x: -50;
    y: -10; }
    .presentation-box__link--image image.centered {
      x: -85; }
  @media (max-width: 767px) {
    .presentation-box:nth-child(3n+2) {
      margin-left: 1%; }
    .presentation-box:nth-child(3n+3) {
      margin-left: 25%;
      margin-right: 1%;
      margin-top: -7.5%;
      margin-bottom: -7.5%; }
      .presentation-box:nth-child(3n+3):last-child {
        margin-bottom: 0; }
    .presentation-box:nth-child(3n+4) {
      clear: left; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .presentation-box {
      width: 32%; }
      .presentation-box__link--title {
        font-size: 1.25rem;
        top: 40%; }
      .presentation-box:nth-child(5n+2) {
        margin-left: 1%;
        margin-right: 1%; }
      .presentation-box:nth-child(5n+4) {
        margin-left: 16.5%;
        margin-right: 1%; }
      .presentation-box:nth-child(5n+4), .presentation-box:nth-child(5n+5) {
        margin-top: -6%;
        margin-bottom: -6%; }
        .presentation-box:nth-child(5n+4):last-child, .presentation-box:nth-child(5n+5):last-child {
          margin-bottom: 0; }
      .presentation-box:nth-child(5n+6) {
        clear: left; } }
  @media (min-width: 1024px) {
    .presentation-box {
      width: 24.25%; }
      .presentation-box__link--title {
        font-size: 1.5rem;
        top: 35%; }
      .presentation-box:nth-child(7n+2), .presentation-box:nth-child(7n+6) {
        margin-left: 1%;
        margin-right: 1%; }
      .presentation-box:nth-child(7n+3) {
        margin-right: 1%; }
      .presentation-box:nth-child(7n+5) {
        clear: left;
        margin-left: 12.5%; }
      .presentation-box:nth-child(7n+5), .presentation-box:nth-child(7n+6), .presentation-box:nth-child(7n+7) {
        margin-top: -5%;
        margin-bottom: -5%; }
        .presentation-box:nth-child(7n+5):last-child, .presentation-box:nth-child(7n+6):last-child, .presentation-box:nth-child(7n+7):last-child {
          margin-bottom: 0; } }
