.hamburger__icon {
  width: 25px;
  position: absolute;
  top: 15px;
  left: 10px; }

.hamburger__links {
  list-style: none;
  width: 75%;
  float: left;
  margin-right: 3.26029%;
  z-index: 20;
  clear: both;
  overflow: hidden;
  top: 0;
  left: 0;
  padding-left: 0;
  height: 100%;
  margin-top: 0;
  background-color: white;
  border-right: solid 1px lightgrey;
  position: fixed;
  transform: translateX(-100%);
  transition: transform .3s;
  overflow-y: auto; }
  .hamburger__links.active {
    transform: translateX(0); }

.hamburger__links--submenu {
  width: 100%;
  z-index: 21; }

.hamburger__close-icon {
  display: none;
  position: absolute;
  width: 35px;
  right: 13%;
  top: 10px;
  z-index: 20; }
  .hamburger__close-icon.active {
    display: block; }

@media (min-width: 768px) and (max-width: 1023px) {
  .hamburger__icon {
    width: 30px;
    top: 20px;
    left: 20px; } }

@media (min-width: 1024px) {
  .hamburger {
    display: none; } }
