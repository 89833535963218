.announcement-important {
  background-color: red;
  width: 30%;
  padding: 5px 2px;
  position: absolute;
  top: 0;
  right: 0; }

.announcement-important-writing {
  color: #ffff;
  text-align: center;
  font-size: 14px; }

.announcement-unimportant {
  display: none; }

@media (min-width: 1024px) {
  .announcement-important {
    width: 20%;
    padding: 8px 2px; } }
