.change-password-page {
  padding: 1px; }
  .change-password-page__content {
    margin-top: 3%;
    padding-bottom: 3%;
    background-color: #ffff;
    border: 1px solid #e0e2e8;
    border-radius: 8px; }
  .change-password-page__title {
    padding: 2% 2% 2%;
    margin: 0;
    color: #4267B2;
    text-align: center; }

.admin-form__field {
  display: flex;
  justify-content: center; }

.label-title {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: -0.25px;
  color: #16233f; }

.label-title-password {
  text-align: center;
  margin-bottom: 5px; }

.general-field {
  width: 662px;
  height: 50px;
  border-radius: 4.3px;
  border: solid 0.7px #becad6;
  background-color: #ffffff;
  font-size: 16px;
  padding-left: 10px; }

.change-password-form {
  display: flex;
  justify-content: center; }

.change-password-field {
  width: 400px;
  height: 40px;
  margin: 10px 20px 20px 20px;
  display: flex;
  justify-content: center;
  margin: 0 auto; }

.general-subtitle {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.4px;
  color: #16233f; }

.change-password-button {
  display: flex;
  justify-content: center;
  margin: 20px auto 0; }
