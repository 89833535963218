.monitor__list {
  list-style-type: square; }
  .monitor__list li {
    margin: 10px 0; }

.monitor__link {
  color: initial;
  text-decoration: none;
  cursor: pointer; }

.monitor__subtitle {
  font-size: 14px;
  color: #0d0d0d; }

@media (min-width: 768px) and (max-width: 1023px) {
  .monitor__list {
    margin-left: 40px; }
    .monitor__list li {
      margin-left: 20px; } }

@media (min-width: 1024px) {
  .monitor__subtitle {
    font-size: 16px;
    margin: 20px 0; }
  .monitor__list {
    margin-left: 100px; }
    .monitor__list li {
      margin-left: 40px; } }
