.download-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around; }

.download-button {
  height: 35px !important;
  position: relative;
  bottom: 5px; }

.view-button {
  width: 30% !important; }

@media (min-width: 1024px) {
  .download-button {
    width: 120%; } }
