.mariage-publications__search-area {
  display: flex;
  flex-direction: column;
  margin: 20px; }

.mariage-publications__input {
  width: 70%;
  margin: 10px auto; }

.mariage-publications__search-icon {
  position: relative;
  right: 60px;
  bottom: 2px;
  width: 14px; }

.mariage-publications__button {
  width: 25%;
  height: 32px;
  border-radius: 3px;
  display: flex;
  margin: 16px auto 10px; }

.mariage-publications__button-writing {
  text-align: center;
  margin: 0 auto; }

.mariage-publications__search-results {
  white-space: wrap;
  overflow-x: scroll;
  position: relative; }
  .mariage-publications__search-results::-webkit-scrollbar-track {
    background-color: transparent; }
  .mariage-publications__search-results::-webkit-scrollbar {
    width: 12px;
    background-color: transparent; }
  .mariage-publications__search-results::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 16px;
    border: 4px solid #fff; }

.mariage-publications tr:hover {
  background-color: #eef4f6; }

.mariage-publications__table-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  color: #404040;
  font-weight: bolder;
  text-align: left;
  white-space: wrap;
  border-bottom: 1px solid #e0eaee; }

.mariage-publications__table {
  padding: 15px 20px;
  width: 230%; }
  .mariage-publications__table:after {
    content: "";
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #e6e6e6 100%);
    height: 100%;
    width: 30%; }
  .mariage-publications__table tr, .mariage-publications__table td {
    padding: 5px; }

.mariage-publications__weds {
  display: flex;
  flex-direction: row; }

.mariage-publications__view-more {
  color: #2386b2;
  cursor: pointer; }

@media (min-width: 768px) and (max-width: 1023px) {
  .mariage-publications__search-area {
    flex-direction: row;
    width: 100%;
    justify-content: center; }
  .mariage-publications__input {
    width: 100%; }
  .mariage-publications__input-box {
    width: 40%; }
  .mariage-publications__search-icon {
    right: 45px;
    bottom: 0px; }
  .mariage-publications__button {
    width: 12%; }
  .mariage-publications__button-writing {
    font-size: 14px; }
  .mariage-publications__table {
    width: 115%; } }

@media (min-width: 1024px) {
  .mariage-publications__search-area {
    flex-direction: row;
    width: 60%;
    justify-content: flex-start;
    margin-left: 10%; }
  .mariage-publications__input {
    width: 100%; }
  .mariage-publications__input-box {
    width: 45%;
    margin-right: 80px; }
  .mariage-publications__search-icon {
    right: 45px;
    bottom: 0px; }
  .mariage-publications__button {
    width: 10%;
    height: 30px;
    position: relative;
    bottom: 3px; }
  .mariage-publications__button-writing {
    font-size: 12px;
    font-weight: lighter; }
  .mariage-publications__search-icon {
    right: 14px; }
  .mariage-publications__table {
    width: 100%;
    padding: 0 10% 30px; }
    .mariage-publications__table tr, .mariage-publications__table td {
      padding: 10px; }
    .mariage-publications__table:after {
      content: "";
      background-image: none; }
    .mariage-publications__table__table-title {
      font-size: 16px; }
  .mariage-publications__publication h3, .mariage-publications__publication h4 {
    width: 25%;
    text-align: center;
    vertical-align: middle;
    text-indent: 0; }
    .mariage-publications__publication h3 span, .mariage-publications__publication h4 span {
      display: block; }
      .mariage-publications__publication h3 span.show-title, .mariage-publications__publication h4 span.show-title {
        font-weight: bold; }
  .mariage-publications__publication-visualize {
    display: inline-block;
    width: 25%; } }
