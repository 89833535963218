.local-concilors__table {
  width: 150% !important; }

@media (min-width: 768px) and (max-width: 1023px) {
  .local-concilors__table {
    width: 100% !important; } }

@media (min-width: 1024px) {
  .local-concilors__table {
    width: 100% !important; } }
