.agricultural-register-switch {
  color: #ffff;
  font-size: lighter; }

.agricultural-register-right-side {
  padding: 15px; }

@media (min-width: 768px) and (max-width: 1023px) {
  .agricultural-register-right-side {
    padding: 30px 15px 15px; } }

@media (min-width: 1024px) {
  .agricultural-register-side {
    padding: 20px 0 20px 10%; }
  .agricultural-register-right-side {
    padding: 30px 15px 15px; } }
