.attachment-form__upload-files {
  padding: 20px; }

.attachment-form__existing-files {
  display: flex;
  justify-content: start;
  align-items: center; }

.attachment-form__file-list {
  list-style: none;
  padding: 0;
  width: 80%;
  padding-right: 10px;
  overflow-wrap: break-word; }
  .attachment-form__file-list li {
    color: rgba(0, 0, 0, 0.4); }

.drop-instruction {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative; }

.upload-indication {
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  color: rgba(0, 0, 0, 0.4);
  margin: 0; }

.upload-icon {
  color: #4987c2;
  padding-right: 2%; }
