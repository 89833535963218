.fc-page__light-section {
  padding: 20px; }

.fc-page__dark-section {
  padding: 20px;
  background-color: #e0eaee; }

.fc-page__important {
  border: 1px solid  #f02020;
  padding: 10px;
  margin: 10px 0; }

.fc-page__description {
  font-weight: lighter;
  margin: 10px 0; }

.fc-page__arrow-icon {
  width: 18px; }

.fc-page__boxes {
  display: flex;
  flex-direction: column; }

.fc-page__second-box {
  background-color: #0d0820; }

.fc-page__switch {
  color: #ffff;
  font-weight: lighter; }

.fc-page__first-box, .fc-page__second-box {
  padding: 20px;
  margin: 10px 0; }

.fc-page__second-important {
  width: 40%;
  margin: 10px auto; }
  .fc-page__second-important h4 {
    text-align: center; }

@media (min-width: 1024px) {
  .fc-page__light-section, .fc-page__dark-section, .fc-page__boxes {
    padding: 30px 10%; }
  .fc-page__boxes {
    flex-direction: row; }
  .fc-page__first-box, .fc-page__second-box {
    width: 50%;
    min-height: 150px; }
  .fc-page__important {
    width: 60%; }
    .fc-page__important h4 {
      font-size: 16px; }
  .fc-page__second-important {
    width: 10%;
    margin-left: 40px; }
    .fc-page__second-important h4 {
      text-align: center; } }
